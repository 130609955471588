(function() {
    'use strict';

    angular.module('ionicForgotPassword', [
        'ionic',
        'ionicAlert',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicForgotPassword').service(
        'ForgotPasswordService',
        ForgotPasswordService
    );

    ForgotPasswordService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'RequestManagementService'
    ];

    function ForgotPasswordService(
        DataManagementService,
        DefaultVariableService,
        RequestManagementService
    ) {
        var ForgotPasswordService = this;

        ForgotPasswordService.loadSecurityQuestion = loadSecurityQuestion;
        function loadSecurityQuestion(username) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'auth');
            options = RequestManagementService.setAction(options, 'security', username);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        if (data.user) {
                            return DefaultVariableService.getString(
                                data.user.security_question
                            );
                        }
                    }

                    return false;
                }
            );
        }

        ForgotPasswordService.getSecurityQuestion = getSecurityQuestion;
        function getSecurityQuestion(username) {
            return ForgotPasswordService.loadSecurityQuestion(username);
        }

        ForgotPasswordService.resetPassword = resetPassword;
        function resetPassword(recover) {
            var username = DefaultVariableService.getString(
                recover.username
            );

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'auth');
            options = RequestManagementService.setAction(options, 'reset', username);

            options = RequestManagementService.setData(options, username);

            return DataManagementService.request(options);
        }

        ForgotPasswordService.reset = reset;
        function reset() {

        }

        ForgotPasswordService.reset();

        return ForgotPasswordService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicForgotPassword').controller(
        'ForgotPasswordController',
        ForgotPasswordController
    );

    ForgotPasswordController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'ForgotPasswordService',
        'RedirectService'
    ];

    function ForgotPasswordController(
        AlertService,
        DefaultVariableService,
        ForgotPasswordService,
        RedirectService
    ) {
        var ForgotPasswordController = this;

        ForgotPasswordController.loadSecurityQuestion = loadSecurityQuestion;
        function loadSecurityQuestion() {
            ForgotPasswordController.isLoading = true;

            return ForgotPasswordService.loadSecurityQuestion(
                ForgotPasswordController.recover.username
            ).then(
                function(security_question) {
                    ForgotPasswordController.recover.security_question = security_question;
                    ForgotPasswordController.showSecurityQuestion = true;

                    return ForgotPasswordController.recover.security_question;
                }
            ).finally(
                function() {
                    ForgotPasswordController.isLoading = false;
                }
            );
        }

        ForgotPasswordController.resetPassword = resetPassword;
        function resetPassword() {
            if (ForgotPasswordController.recover.password !== ForgotPasswordController.recover.password_confirm) {
                AlertService.addMessage('THE ENTERED PASSWORDS DO NOT MATCH.');
            } else {
                ForgotPasswordService.resetPassword(
                    ForgotPasswordController.recover
                ).then(
                    function(success) {
                        if (success) {
                            AlertService.addMessage('PASSWORD SUCCESSFULLY UPDATED.');

                            ForgotPasswordController.reset();

                            RedirectService.goTo(
                                ForgotPasswordController.sref
                            );
                        }

                        return success;
                    }
                );
            }
        }

        ForgotPasswordController.toggleForgotPassword = toggleForgotPassword;
        function toggleForgotPassword() {
            ForgotPasswordController.forgotPassword = !ForgotPasswordController.forgotPassword;
        }

        ForgotPasswordController.reset = reset;
        function reset() {
            ForgotPasswordController.isLoading = false;

            ForgotPasswordController.forgotPassword = false;

            ForgotPasswordController.showSecurityQuestion = false;

            ForgotPasswordController.recover = {};
            ForgotPasswordController.recover.password = '';
            ForgotPasswordController.recover.password_confirm = '';
            ForgotPasswordController.recover.security_answer = '';
            ForgotPasswordController.recover.security_question = '';
            ForgotPasswordController.recover.username = '';

            ForgotPasswordController.sref = DefaultVariableService.get(
                ForgotPasswordController.sref,
                'app.login'
            );
        }

        ForgotPasswordController.init = init;
        function init() {
            ForgotPasswordController.reset();
        }

        ForgotPasswordController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicForgotPassword').directive('ionicForgotPassword', ionicForgotPassword);

    function ionicForgotPassword() {
        return {
            bindToController: {
                sref:           '@'
            },
            controller:   'ForgotPasswordController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div data-ng-if="!ctrl.isLoading"><div class="list animated fadeInDown" data-ng-if="!ctrl.showSecurityQuestion"><label class="item item-input item-floating-label"><span class="input-label">USERNAME OR EMAIL ADDRESS</span> <input type="text" placeholder="USERNAME OR EMAIL" data-ng-model="ctrl.recover.username"></label> <button class="button button-full button-balanced accept-button" data-ng-click="ctrl.loadSecurityQuestion()">SUBMIT</button></div><div class="list animated fadeInDown" data-ng-if="ctrl.showSecurityQuestion"><label class="item item-input item-floating-label"><span class="input-label">{{ ctrl.recover.security_question | uppercase }}</span> <input type="text" placeholder="{{ ctrl.recover.security_question | uppercase }}" data-ng-model="ctrl.recover.security_answer"></label> <label class="item item-input item-floating-label"><span class="input-label">NEW PASSWORD</span> <input type="password" placeholder="NEW PASSWORD" data-ng-model="ctrl.recover.password"></label> <label class="item item-input item-floating-label"><span class="input-label">CONFIRM NEW PASSWORD</span> <input type="password" placeholder="CONFIRM NEW PASSWORD" data-ng-model="ctrl.recover.password_confirm"></label> <button class="item button button-full button-balanced accept-button" data-ng-click="ctrl.resetPassword()">RESET</button></div><button class="item button button-full button-assertive decline-button animated fadeInDown" data-ui-sref="{{ ctrl.sref }}">CANCEL</button></div><span data-ng-if="ctrl.isLoading"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();